import { useEffect, useState } from 'react';
import { useMobileApp } from '../hooks/useMobileApp';
import { useRouter } from 'next/router';
import useIntercom from '../hooks/useIntercom';
import { useSelector } from '../reduxToolkit/typedSelector';

const disabledPaths = ['/output/', '/studiopreview'];

const Intercom = () => {
  const event = useSelector((state) => state.common.event ?? state.admin.event);
  const meeting = useSelector((state) => state.video?.meeting);
  const showIntercomButton = useSelector((state) => state.common.showIntercomButton);
  const user = useSelector((state) => state.common.user);
  const intercomId = useSelector((state) => state.common.intercomId);
  const organization = useSelector((state) => state.common.user?.organization ?? state.common.event?.organization);

  const [hasConsent, setHasConsent] = useState(false);
  const [hideIntercomOnEvent, setHideIntercomOnEvent] = useState(false);
  const [pageType, setPageType] = useState<'account' | 'event'>();
  const [hideIntercom, setHideIntercom] = useState(false);

  const router = useRouter();
  const intercom = useIntercom();
  const { isAppView } = useMobileApp();

  function checkCookieSettings() {
    if (window && (window as any).Cookiebot) {
      const bot = (window as any).Cookiebot;
      setHasConsent(bot?.consent?.necessary);
    }
  }

  function checkIntegrationSettings() {
    const showOnEvent =
      event?.organization?.integrations?.intercom?.showIntercomSupport !== false &&
      meeting?.event?.organization?.integrations?.intercom?.showIntercomSupport !== false &&
      meeting?.booth?.event?.organization?.integrations?.intercom?.showIntercomSupport !== false;

    setHideIntercomOnEvent(!showOnEvent);
  }

  function determinePageType() {
    const isAccount = router.asPath?.includes('/account');
    const isEvent = !isAccount && router.asPath?.includes('/event/');
    setPageType(isAccount ? 'account' : isEvent ? 'event' : undefined);
  }

  useEffect(() => {
    if (!hideIntercom) intercom.setup();
  }, [hideIntercom]);

  useEffect(() => {
    if (isAppView()) intercom.shutdown();
  }, [isAppView]);

  useEffect(() => {
    if (!window) return;
    checkCookieSettings();
    window.addEventListener('CookiebotOnLoad', checkCookieSettings);

    return function cleanup() {
      window?.removeEventListener('CookiebotOnLoad', checkCookieSettings);
    };
  }, [event]);

  useEffect(() => {
    determinePageType();

    if (router.pathname.includes('videochat')) {
      intercom.update({ vertical_padding: 80 });
    }
  }, [router]);

  useEffect(() => {
    intercom.toggleIntercomButton(!hideIntercom);
  }, [hideIntercom]);

  useEffect(() => {
    if (!hasConsent) {
      intercom.shutdown();
    } else {
      !hideIntercom && intercom.setup();
    }
  }, [hasConsent, hideIntercom]);

  useEffect(() => {
    if (organization?.integrations?.intercom.intercomId) intercom.setIntercomId(organization?.integrations?.intercom.intercomId);
  }, [organization?.integrations?.intercom]);

  useEffect(() => {
    checkIntegrationSettings();
  }, [event, meeting]);

  useEffect(() => {
    intercom.update();
  }, [user, event, pageType, intercomId, showIntercomButton]);

  useEffect(() => {
    const hide =
      showIntercomButton === false || disabledPaths.some((path) => router.pathname.includes(path)) || (pageType === 'event' && (hideIntercomOnEvent || !event));
    setHideIntercom(hide);
  }, [showIntercomButton, hideIntercomOnEvent, router, event, pageType]);

  return null;
};

export default Intercom;
