import cookie from 'cookie';
import { formatDistanceToNow, getHours, getMinutes } from 'date-fns';
import { de, enUS, fr } from 'date-fns/locale';
import { GetServerSidePropsContext, GetStaticPropsContext } from 'next';
import { i18n } from 'next-i18next';
import UAParser from 'ua-parser-js';
import colorConvert from 'color-convert';
import { baymeCustomTemplateOrgId, mbwCustomTemplateOrgId, vbwCustomTemplateOrgId } from '../static/constants';

// Check if category background is too bright or dark in order to decide the text color
// from https://awik.io/determine-color-bright-dark-using-javascript/
export function lightOrDark(color: any) {
  // Variables for red, green, blue values
  let r;
  let g;
  let b;
  let hsp;

  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    // If RGB --> store the red, green, blue values in separate variables
    color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    // If hex --> Convert it to RGB: http://gist.github.com/983661
    color = +`0x${color.slice(1).replace(color.length < 5 && /./g, '$&$&')}`;

    r = color >> 16;
    g = (color >> 8) & 255;
    b = color & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {
    return 'light';
  }
  return 'dark';
}

export function redirectWithUrlParam() {
  const redirectToken = getQueryParam('redirect');
  if (redirectToken !== false && typeof window !== 'undefined') {
    window.location.href = window.location.origin + '/' + atob(String(redirectToken));
    return true;
  }
  return false;
}

const locales: { [key: string]: Locale } = {
  de: de,
  en: enUS,
  fr: fr,
};

export function getDateDifference(date: any) {
  if (!i18n) return '';
  date = new Date(date);
  let dateToNowString = formatDistanceToNow(date);

  if (!dateToNowString.match(/\d+/)) return formatDistanceToNow(date, { locale: locales[i18n.language] });
  const number = dateToNowString.match(/\d+/)![0];
  if (dateToNowString.includes('minutes')) dateToNowString = i18n.t('event:N-minutes', { number });
  else if (dateToNowString.includes('hours')) dateToNowString = i18n.t('event:about-N-hours', { number });
  else if (dateToNowString.includes('days')) dateToNowString = i18n.t('event:N-days', { number });
  else if (dateToNowString.includes('months')) dateToNowString = i18n.t('event:N-months', { number });
  else if (dateToNowString.includes('years'))
    if (dateToNowString.includes('almost')) dateToNowString = i18n.t('event:almost-N-years', { number });
    else if (dateToNowString.includes('about')) dateToNowString = i18n.t('event:about-N-years', { number });
    else if (dateToNowString.includes('over')) dateToNowString = i18n.t('event:over-N-years', { number });
  return dateToNowString;
}

export function getRedirectParam() {
  return `redirect=${btoa(unescape(encodeURIComponent(window.location.pathname + window.location.search)))}`;
}

export function getQueryParam(param: string, url?: string) {
  const value = url || window.location.search;
  let result = value.match(new RegExp('(\\?|&)' + param + '(\\[\\])?=([^&]*)'));
  return result ? result[3] : false;
}

// this function takes a date as paremeter (d) and returns decimal hours number
// e.g : 08:00PM returns 20
// it is used to define the appointment timeline width
// backup is a default value used to avoid errors when date is undefined
export function dateToTimeline(backup: number, isStart: boolean, d?: string) {
  if (!d) return backup;
  const date = new Date(d);
  const time = getMinutes(date) / 60 + getHours(date);
  if (isStart) return Math.floor(time) === 0 ? Math.floor(time) : Math.floor(time) - 1;
  else return Math.floor(time) + 1;
}

interface UnsupportedBrowserObject {
  name: string; // list: https://github.com/faisalman/ua-parser-js#methods
  version: null | number;
}

const UNSUPPORTED_BROWSERS: UnsupportedBrowserObject[] = [
  { name: 'IE', version: null },
  // { name: 'IEMobile', version: null },
  { name: 'Edge', version: 79 }, // and below is implied
];

export function isSupportedBrowser() {
  const parser = new UAParser();
  const { name, version } = parser.getBrowser();
  /**
   * We return true if we do not have a name
   * and a version as the page might still
   * be loading - we do not want to show a
   * browser not supported message by default
   *
   */
  if (!name || !version) return true;
  const unsuportedBrowser = UNSUPPORTED_BROWSERS.find((ub) => ub.name === name);
  if (!unsuportedBrowser) return true;
  if (!unsuportedBrowser.version) return false;
  if (parseInt(version) >= unsuportedBrowser.version) return true;
  return false;
}

export function getLocaleFromServerSideContext(props: GetServerSidePropsContext): string {
  const cookieLanguage = getNextLocaleCookie(props.req.headers.cookie);
  return cookieLanguage || props.locale || props.defaultLocale || 'de';
}

export function getNextLocaleCookie(cookieString: string | undefined) {
  if (!cookieString) return;
  const parsedCookies = cookie.parse(cookieString);
  return parsedCookies['NEXT_LOCALE'];
}

export function getLocaleFromStaticContext(context: GetStaticPropsContext) {
  return context?.locale || context?.defaultLocale || 'de';
}

// linkify string urls
// use tailwind for style
export function linkify(text: string, style = '') {
  let urlRegex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
  return text.replace(urlRegex, function (url) {
    let urlValid = url;
    if (!/^http[s]?:\/\//i.test(urlValid)) {
      urlValid = 'http://' + url;
    }
    return '<a class="' + style + '" rel="noopener noreferrer" target="_blank" href="' + urlValid + '">' + url + '</a>';
  });
}

export function downloadFileFromExternalLink(url: string, filename?: string, onProgress?: (progressPercent: number) => void, onDownloadEnd?: () => void) {
  var _filename = filename || 'media';
  var xhr = new XMLHttpRequest();
  xhr.responseType = 'blob';
  xhr.onprogress = function (progressEvent) {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    onProgress?.(percentCompleted);
  };
  xhr.onload = function () {
    var a = document.createElement('a');
    a.href = window.URL.createObjectURL(xhr.response); // xhr.response is a blob
    a.download = _filename; // Set the file name.
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    onDownloadEnd?.();
  };
  xhr.open('GET', url);
  xhr.send();
}

export async function getFileFromUrl(url: string, name: string, defaultType = 'image/jpeg') {
  const response = await fetch(url);
  const data = await response.blob();
  return new File([data], name, {
    type: data.type || defaultType,
  });
}

export function getDaysArray(start: Date, end: Date) {
  for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
    arr.push(new Date(dt));
  }
  return arr;
}

export function randomString(length: number): string {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function RGBAToHexA(rgba: string, forceRemoveAlpha = false) {
  return (
    '#' +
    rgba
      .replace(/^rgba?\(|\s+|\)$/g, '') // Get's rgba / rgb string values
      .split(',') // splits them at ","
      .filter((string, index) => !forceRemoveAlpha || index !== 3)
      .map((string) => parseFloat(string)) // Converts them to numbers
      .map((number, index) => (index === 3 ? Math.round(number * 255) : number)) // Converts alpha to 255 number
      .map((number) => number.toString(16)) // Converts numbers to hex
      .map((string) => (string.length === 1 ? '0' + string : string)) // Adds 0 when length of one number is 1
      .join('')
  ); // Puts the array to togehter to a string
}

export const getContrastColor = (contrastColor = '#4896f4') => {
  if (contrastColor) {
    const [h, s] = colorConvert.hex.hsl(contrastColor);
    const randomToHundred = Math.floor(Math.random() * 50) + 30;
    return `hsl(${h}, ${s}%, ${randomToHundred}%)`;
  }
};

export const scrollToBottomOfELementById = (id: string) => {
  const el = document.getElementById(id);
  if (el) {
    el.scrollTo({ top: el.scrollHeight, behavior: 'smooth' });
  }
};

export const getScrollPositionFromBottom = (id: string) => {
  const el = document.getElementById(id);
  if (el) {
    return el.scrollHeight - el.scrollTop - el.clientHeight;
  }
  return 0;
};

export const isMbwOrVbw = (organizationId: string) => {
  if (process.env.NODE_ENV === 'production') {
    return organizationId === mbwCustomTemplateOrgId || organizationId === vbwCustomTemplateOrgId || organizationId === baymeCustomTemplateOrgId;
  } else {
    // org of dev2@vy.io
    return organizationId === '6225cf867347301e5ccf00af';
  }
};
